<script>
import { BFormFile } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import fileRenderer from '@/views/components/renderers/file-renderer';

export default {
  props: {
    value: {
     
    },
    invalid: {
      type: Boolean
    },
    extensions: {
      type: String
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      p_value: null,
      file: null,
      uploading: null
    }
  },
  watch: {
    value(nv, ov) {
      if(nv != ov) {
        this.updateInternalValue();
      }
    }
  },
  components: {  
    BFormFile,
    fileRenderer
  },
  computed: {
    ...mapGetters('account', {
      maxFileSize: 'maxFileSize'
    })
  },
  created() {
    this.updateInternalValue();
  },
  methods: {
    updateInternalValue() {
      this.p_value = this.value;
    },
    onChange() {
      this.$emit('input', this.p_value);
    },
    clear() {
      this.file = null;
      this.p_value = null;
      this.onChange();
    },
    uploadFile(fileAux) {
      const p = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          const blob = new Blob([fileReader.result], {
            type: fileAux.type
          });
          if (blob.size > this.maxFileSize) {
            this.$swal.fire(
              this.$t('common.unable-send-file'),
              this.$t('common.file-size-is-to-big'),
              'warning'
            );
            return reject('File size is to big');
          }
          const fileInfo = {
            fileName: fileAux.name,
            mimeType: fileAux.type,
            size: blob.size
          };

          const formData = new FormData();
          formData.append('file', blob, fileInfo.fileName);
          formData.append('json', JSON.stringify(fileInfo));
          try {
            const response = await this.$http({
              method: 'post',
              url:  `files`,
              data: formData,
              headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
              }
            });
            this.p_value = {
              Id: response.data.Id,
              Name: response.data.Name,
              MimeType: response.data.MimeType,
              Size: response.data.Size || 0,
              AccessId: response.data.AccessId,
              IsImage: response.data.IsImage,
              IsPdf: response.data.IsPdf
            }
            resolve(this.p_value);
          } catch(e) {
            console.error(e);
          } finally {
          
          } 
        };
        fileReader.readAsArrayBuffer(fileAux);
      });
      return p;
    },
    async upload () {
      if(Array.isArray(this.file)) {
        this.uploading = true;
        let res = [];
        try {
          for(let i = 0; i < this.file.length; i++) {
            const fileInfo = await this.uploadFile(this.file[i]);
            res.push(fileInfo);
          }
          this.p_value = res;
        } finally {
          this.uploading = false;
          this.file = null;
        }
        
        this.onChange();
      } else {
        const fileAux = this.file;
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          const blob = new Blob([fileReader.result], {
            type: fileAux.type
          });
          if (blob.size > this.maxFileSize) {
            this.$swal.fire(
              this.$t('common.unable-send-file'),
              this.$t('common.file-size-is-to-big'),
              'warning'
            );
            return new Error('File size is to big');
          }
          const fileInfo = {
            fileName: fileAux.name,
            mimeType: fileAux.type,
            size: blob.size
          };

          const formData = new FormData();
          formData.append('file', blob, fileInfo.fileName);
          formData.append('json', JSON.stringify(fileInfo));
          this.uploading = true;
          try {
            const response = await this.$http({
              method: 'post',
              url:  `files`,
              data: formData,
              headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
              }
            });
            this.p_value = {
              Id: response.data.Id,
              Name: response.data.Name,
              MimeType: response.data.MimeType,
              Size: response.data.Size || 0,
              AccessId: response.data.AccessId,
              IsImage: response.data.IsImage,
              IsPdf: response.data.IsPdf
            }
            this.onChange();

          } catch(e) {
            console.error(e);
          } finally {
            this.file = null;
            this.uploading = false;
          } 
        };
        fileReader.readAsArrayBuffer(fileAux)
      }
     
    }
  }
};
</script>

<template>

    <div v-if="p_value">
      <div class="input-group">
        <div class="form-control">
            <file-renderer :value="p_value"></file-renderer>
        </div>
        <div class="input-group-prepend">
          <button class="btn btn-outline-secondary" type="button" @click="clear">
            {{$t('common.clear-button-title')}}
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="!p_value">
      <div v-if="!uploading">
        <b-form-file
          v-model="file"
          ref="file-input"
          :state="invalid ? false : null"
          :accept="extensions || null"
          :placeholder="$t('common.choose-file')"
          :browse-text="$t('common.browse-file')"
          :multiple="multiple" 
          @input="upload"
        />
      </div>
      <div v-if="uploading">
        <div class="form-control">
          <font-awesome-icon icon="fa-solid fa-spinner" class="fa-svg-spin"/>
        </div>
      </div>
    </div>

</template>

<style lang="scss" scoped>
 
</style>
  