import store from '@/store'

export const LoadingMinDuration = 400;
export const DirectlyAssigningID = "@directly-assigning";

export function addQueryParameter(name, value) {
    const searchURL = new URL(window.location);
    searchURL.searchParams.set(name, value);
    window.history.pushState({}, '', searchURL);
}

export function getConditionalOperatorsByField(field) {

    const res = [
        'eq',
        'ne',
        'null',
        'notnull'
    ]

    if(field.type === 'String') {
        res.push('contains');
        res.push('startsWith');
    } else if(field.type === 'YesOrNo') {
    
    } else if(field.type === 'Date' || field.type === 'DateTime') {
        res.push('ge');
        res.push('gt');
        res.push('le');
        res.push('lt');
    } else if(field.type === 'DateTime') {
        res.push('ge');
        res.push('gt');
        res.push('le');
        res.push('lt');
    }  else if(field.type === 'Number') {
        res.push('ge');
        res.push('gt');
        res.push('le');
        res.push('lt');
    } else  if(field.type === 'Address') {
        res.push('contains');
        res.push('startsWith');
    } else  if(field.type === 'PhoneNumber') {
        res.push('contains');
        res.push('startsWith');
    } else  if(field.type === 'Email') {
        res.push('contains');
        res.push('startsWith');
    } else  if(field.type === 'User') {
    
    } else  if(field.type === 'UserGroup') {
    
    } else  if(field.type === 'Reference') {
    
    }
    return res; 
}

export function processObjectGraph(graph) {
  
    var graphToMap = (source, map) => {
        if(Array.isArray(source)) {
            source.forEach(e => {
                graphToMap(e, map);
            });
        } else if(source instanceof Object) {
            
            if(source.Id && source.Number) {
                if(map[source.Id]) return;

                map[source.Id] = source;
                const keys = Object.keys(source);
                keys.forEach(key => {
                    graphToMap(source[key], map);
                });
               
            }
        }
    }

    var fixRefs = (source, map, handled) => {
        if(handled.has(source)) return source;
        handled.add(source);

        const keys = Object.keys(source);
        keys.forEach(key => {
            const o = source[key];
            if(Array.isArray(o)) {
                source[key] = o.map(x => {
                    if(x.$ref) {
                        return map[x.Id];
                    } else {
                        return fixRefs(x, map, handled);
                    }
                })
            } else if(o instanceof Object) {
                if(o.$ref) {
                    source[key] = map[o.Id];
                } else {
                    if(source.Id && source.Number) {
                        fixRefs(o, map, handled);
                    }
                }
            }
        });
        return source;
    };

    let map = {};

    graphToMap(graph, map);
    let handled = new Set();
   return Array.isArray(graph) ? graph.map(x => fixRefs(x, map, handled)) : fixRefs(graph, map, handled);
  // return graph;
};

export function getDisplayValueFieldName(entity) {
    const displayField = entity.fields.find(x => x.id === entity.displayValueFieldId);
    return displayField?.name || 'DisplayValue';
}

export function createExpandExpression(entity, path, $expand) {
    if(!path) return null;
    let oDataFieldPath = convertFieldPathToOData(entity, path);
    if(!oDataFieldPath) {
        return $expand;
    }
   
    const segments = oDataFieldPath.split('/');
    let epath = null;
    let nestedLevel = 0;
    for(let i = 0; i < segments.length; i++) {
        const np = epath ? (epath + '.' + segments[i]) : segments[i];
        const fi = findField(entity, np);
       
        if(fi.field.type === 'Reference' || fi.field.type === 'User' || fi.field.type === 'UserGroup' || fi.field.type === 'File') {
            if(epath) {
                
                epath += '($expand=' + fi.field.name;
                nestedLevel++;
            } else {
                if(fi.field.type === 'Reference') {
                   if(!$expand) {
                        const refEntity = store.state.configuration.model.entities.find(x => x.id === fi.field.referenceToEntity);
                        if(refEntity.displayValueField?.type === 'Reference') {
                            epath = fi.field.name + '($expand=' + refEntity.displayValueField.name;
                            nestedLevel++;
                          
                        } else {
                            epath = fi.field.name;
                        }
                   } else {
                        epath = fi.field.name;
                   }
                   
                } else {
                    epath = fi.field.name;
                }
            }
       } else {
        break;
       }
    }
    for(let n = 0; n < nestedLevel; n++) {
        epath += ')';
    }
    
    if(epath) {
       if($expand?.length > 0) {
            $expand += ','+ epath;
        } else {
            $expand = epath;
        }
    }

    if($expand) {
        const a = $expand.split(',');
        const s = new Set(a);
        $expand = [...s].join(',');
    }
   
    return  $expand;
}

export function convertFieldPathToOData(entity, path) {
    if(!path) return null;
    const entities = store.state.configuration.model.entities;
    if(path.includes('.') > 0) {
        let res = '';
        const segments = path.split('.');
        let currentEntity = entity;
        for(let i = 0; i < segments.length; i++) {
            const segment = segments[i];
            const field = currentEntity.fields.find(x => x.id === segment || x.name === segment);
           
            if(!field) return null;
            if(i > 0) {
                res += '/';
            }
            res += field.name;
         
            currentEntity = entities.find(x => x.id === field.referenceToEntity);
            if(!currentEntity) return res;
        }
       
        return res || null;
    } else {
        if(path === 'Number') {
            return 'Number';
        }
        const field = entity.fields.find(x => x.id === path || x.name === path);
        return field?.name;
    }
}

export function pathWithIdToPathWithName(path) {
    if(!path) return '';
    const segments = path.split('.');
    let tramsformed = [];
    segments.forEach(s => {
      if(store.state.configuration.model.fieldMap[s]) {
        tramsformed.push(store.state.configuration.model.fieldMap[s]);
      } else {
        tramsformed.push(s);
      }
    });
    return tramsformed.join('.');
}

export function findField(entity, path) {
    if(!path) return null;

    const entities = store.state.configuration.model.entities;
    if(path.includes('.') > 0) {
        const segments = path.split('.');
        const lastSegment = segments[segments.length - 1];
        for(let i = 0; i < entities.length; i++) {
            const field = entities[i].fields.find(x => x.id === lastSegment || x.name === lastSegment);
            if(field) {
                return {
                    field, 
                    entity: entities[i]
                };
            }
        }

    } else {
        const field = entity.fields.find(x => x.id === path || x.name === path);
        if(field) {
            return {
                field, 
                entity
            };
        }
    }
    return null;
}

export function getValueByPath(obj, path, level = 0) {
    if(level > 10) return null;
    if(!obj || !path) return null; 
   
    const fieldMap = store.state.configuration.model.fieldMap;
    
    const segments = path.split('.');
   
    let e = obj;
    let res = [];
    let value = null;
    for(let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        const fieldName = fieldMap[segment] ? fieldMap[segment] : segment;

        value = e[fieldName];
        
        if(Array.isArray(value)) {
            
            const subPathSegments = segments.slice(i+1);
            if(!subPathSegments || subPathSegments.length === 0) {
                res = [...res, ...value];
            } else {
                const subPath = subPathSegments.join('.');
                
                value.forEach(arrayItem => {
                    const subres = getValueByPath(arrayItem, subPath, level++);
                   
                    if(subres) {
                        if(Array.isArray(subres)){
                            res = [...res, ...subres];
                        } else{
                            res.push(subres);
                        }
                    } 
                });
            }
            
        } else {
            e = value;
        }
       
        if(e == null) break;
    }
    if(value && !Array.isArray(value)) {
        res.push(value);
    }
    return res.length <= 1 ? (res[0] || null) : res;
}

export function sortByPath(entity, array, path) {
    if(path === "DisplayValue") {
        let o = array || [];
        o.sort((a, b) => {
            const av = a.DisplayValue;
            const bv = b.DisplayValue;
            return (av || '').localeCompare(bv || '');
        });
        return o;
    } else {
        const fieldInfo = findField(entity, path);
        let compareFunc = (av, bv) => av >= bv;
        if(fieldInfo.field.type === 'Address') {
        compareFunc = (av, bv) => (av?.Value || '').localeCompare(bv?.Value || '');
        } else if(fieldInfo.field.type === 'String' || fieldInfo.field.type === 'Email' || fieldInfo.field.type === 'PhoneNumber') {
        compareFunc = (av, bv) => (av || '').localeCompare(bv || '');
        }
        let o = array || [];
        o.sort((a, b) => {
            const av = getValueByPath(a,path);
            const bv = getValueByPath(b,path);
            return compareFunc(av, bv);
        });
        return o;
    }
   
}

export function getAgGridRenderer(entity, path) {
    const fieldInfo = findField(entity, path);
    let cellRenderer = 'stringRenderer';
    let params = null;
    if(fieldInfo) {
        if(fieldInfo.field.type === 'String') {
            cellRenderer = 'stringRenderer';
        } else if(fieldInfo.field.type === 'YesOrNo') {
            cellRenderer = 'yesnoRenderer';
        } else if(fieldInfo.field.type === 'Date') {
            cellRenderer = 'dateRenderer';
        } else if(fieldInfo.field.type === 'Time') {
            cellRenderer = 'timeRenderer';
        } else if(fieldInfo.field.type === 'DateTime') {
            cellRenderer = 'datetimeRenderer';
        } else if(fieldInfo.field.type === 'Number') {
            cellRenderer = 'numberRenderer';
        } else if(fieldInfo.field.type === 'Price') {
            cellRenderer = 'priceRenderer';
        } else if(fieldInfo.field.type === 'Address') {
            cellRenderer = 'addressRenderer';
        } else if(fieldInfo.field.type === 'PhoneNumber') {
            cellRenderer = 'phonenumberRenderer';
        } else if(fieldInfo.field.type === 'Email') {
            cellRenderer = 'emailRenderer';
        } else if(fieldInfo.field.type === 'User') {
            cellRenderer = 'userRenderer';
        } else if(fieldInfo.field.type === 'UserGroup') {
            cellRenderer = 'usergroupRenderer';
        } else if(fieldInfo.field.type === 'File') {
            cellRenderer = 'fileRenderer';
        } else if(fieldInfo.field.type === 'Reference' && !fieldInfo.field.isList) {
            cellRenderer = 'ag-grid-object-renderer';
            params = {entityId: fieldInfo.field.referenceToEntity}
        } else if(fieldInfo.field.type === 'Reference' && fieldInfo.field.isList) {
            cellRenderer = 'ag-grid-object-list-renderer';
            params = {entityId: fieldInfo.field.referenceToEntity}
        } 
    }
    return { cellRenderer, params };
}

export function fieldToString(entity, field, fieldValue) {
   if(field.type === 'Reference') return fieldValue?.DisplayValue || null;
   if(field.type === 'Address') return fieldValue?.Value || null;
   
   return fieldValue;
}
