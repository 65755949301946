<script>
import Ripple from 'vue-ripple-directive'
import { BPopover, BButton, BOverlay } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import clickOutside from 'v-click-outside';
import {
  BModal
} from 'bootstrap-vue'

export default {
  name: 'file-renderer',
  components: {
    BPopover,
    BButton,
    BModal,
    BOverlay
  },
  directives: {
    Ripple,
    clickOutside
  },
  props: ['value', 'preview', 'centered', 'maxHeight', 'draggable', 'allowDelete'],
  data() {
    return {
      popoverShow: false,
      modalShow: false,
      urlGeneration: false,
      url: null,
      downloading: false
    }
  },
  methods: {
    ...mapActions('auth',{
      refreshAuth: 'refreshToken'
    }),
    onClose() {
     
      this.popoverShow = false
    },
    showPreview() {
      this.popoverShow = false;
      this.modalShow = true;
    },
   
    async generateUrl() {
     
      this.urlGeneration = true;
      this.url = null;
     
      this.url = `${process.env.VUE_APP_URL}files/${this.value.AccessId || this.value.accessId}?token=${this.token}`;
    },
    iframeLoaded(evt) {
    
      this.urlGeneration = false;
    },
    async download() {
      this.downloading = true;
      await this.refreshAuth();
      const downloadUrl = `${process.env.VUE_APP_URL}files/${this.value.AccessId || this.value.accessId}?token=${this.token}&attachment=true`;
      try {
        // const resp = await this.$http.get(downloadUrl, {}, { responseType: 'blob' });
        // const objUrl = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', this.value.Name || this.value.name); //or any other extension
        document.body.appendChild(link);
        link.click();
      } finally {
        this.downloading = false;
      }
    },
  },
  computed: {
    ...mapGetters('users', {
      users: 'users'
    }),
    ...mapGetters('auth', {
      token: 'token'
    }),
    isImage() {
      return this.value.isImage || this.value.IsImage;
    },
    isPdf() {
      return this.value.isPdf || this.value.IsPdf;
    },
    createdBy() {
      return this.users.find(x => x.id === (this.value.CreatedBy || this.value.createdBy));
    },
    previewUrl() {
     return `${process.env.VUE_APP_URL}files/${this.value.AccessId || this.value.accessId}?token=${this.token}`;
   },
  }
};
</script>

<template>
  <span v-if="value">
    <b-modal
    
      hide-footer
      class="modal-fullscreen"
      size="fullscreen"
      v-model="modalShow"
      @shown="generateUrl"
    >
      <template #modal-header>
        <button @click="download"  class="text-nowrap text-truncate btn btn-primary" :disabled="downloading">
          <font-awesome-icon icon="fa-solid fa-spinner" class="fa-svg-spin mr-50" v-if="downloading"/>
          <feather-icon icon="DownloadIcon" class="mr-50" v-if="!downloading"/>
          {{$t('common.download')}}
          <span class="d-none d-lg-inline-block">: {{value.Name || value.name}}</span>
        </button>
        <button type="button" aria-label="Close" class="close"  @click="modalShow = false">×</button>
      </template>
      <b-overlay
        style="height:100%"
        :show="urlGeneration"
      >

        <iframe v-if="url" @load="iframeLoaded" :src="url" ref="iframe" style="width:100%; height: 100%;"  allowTransparency="true" frameborder="0" scrolling="yes">
        </iframe> 
      </b-overlay>
    </b-modal>
   
    <div v-if="preview && isImage" @click="showPreview" class="cursor-pointer position-relative" :class="{'text-center': centered}">
      <img :src="previewUrl" class="img-thumbnail" :style="maxHeight ? 'max-height:' + maxHeight : ''"/>
      <div style="position: absolute;top:3px; left:3px; " class="draggable-handle px-25 py-50 bg-light" v-if="draggable">
        <feather-icon
          icon="MoreVerticalIcon"
          class="draggable-handle d-inline"
          size="18"
        />
      </div>
    </div>
    <div v-else-if="preview && isPdf" @click="showPreview" class="cursor-pointer position-relative" :class="{'text-center': centered}" style="position: relative;">
      <iframe :src="previewUrl" ref="iframe" style="width:100%;pointer-events: none;"  :style="maxHeight ? 'max-height:' + maxHeight : ''"  frameborder="0" scrolling="false">
      </iframe> 
      <div style="position: absolute; left:0; top:0; right:0; bottom: 0;" click="showPreview"></div>
      <div style="position: absolute; top:3px; left:3px; " class="draggable-handle px-25 py-50 bg-light" v-if="draggable">
        <feather-icon
          icon="MoreVerticalIcon"
          class="draggable-handle d-inline"
          size="18"
        />
      </div>
    </div>
    <div v-else class="cursor-pointer position-relative" :class="{'text-center': centered}">
      <!-- <img v-if="preview" src="/no-image.png" class="img-thumbnail" click="showPreview"  :style="maxHeight ? 'max-height:' + maxHeight : ''"/> -->
      <div style="position: absolute; top:3px; left:3px; " class="draggable-handle px-25 py-50 bg-light" v-if="draggable">
        <feather-icon
          icon="MoreVerticalIcon"
          class="draggable-handle d-inline"
          size="18"
        />
      </div>
    </div>
    <div  class="cursor-pointer d-flex justify-content-between align-items-center " :class="{'text-center': centered}">
      <a href="javascript:void(0)" ref="doclnk" :class="{'pl-4' : draggable}">
        <b>{{value.Name || value.name}}</b>
      </a>
      <b-button variant="light" class="text-warning" size="sm"  v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="$emit('delete')" v-if="allowDelete">
        <feather-icon
          icon="TrashIcon"
          class="mr-25 text-warning"
        />  
        {{$t('common.delete-button-title')}}
       
      </b-button>
      <b-popover
        ref="popover"
        :target="() => $refs.doclnk"
        triggers="click blur"
        :show.sync="popoverShow"
        placement="auto"
        variant="outline-secondary"
        
      >
        <template #title>
          <div class="d-flex justify-content-between align-items-center text-nowrap text-truncate">
            {{$t('common.about-file')}}
          </div>
        </template>

        <div class="">
          <dl>
            <dt>
              {{$t('common.file-name')}}:
            </dt>
            <dd>
              {{value.Name || value.name}}
            </dd>
            <dt>
              {{$t('common.file-created-at')}}:
            </dt>
            <dd>
              {{(value.CreatedAt || value.createdAt) | timeAndDate('L LT')}}
            </dd>
            <dt>
              {{$t('common.file-created-by')}}:
            </dt>
            <dd>
              <span v-if="createdBy"> {{createdBy.name}}</span>
              <span v-if="!createdBy">...</span>
            </dd>
          </dl>
      
        </div>
        <hr/>
        <div class="text-center">
          
          <a href="javascript:void(0)" class="btn btn-primary btn-block btn-sm" @click="showPreview" v-if="(value.IsPdf || value.isPdf) || (value.IsImage || value.isImage)">
            {{$t('common.open-button-title')}}
          </a>
          <a href="javascript:void(0)" class="btn btn-primary btn-block btn-sm" @click="download" v-if="!value.isPdf && !value.IsPdf && !value.isImage && !value.IsImage">
            {{$t('common.download-button-title')}}
          </a>
          <b-button
            class="btn-block mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="secondary"
            
            @click="onClose"
          >
            {{$t('common.close-button-title')}}
          </b-button>
        </div>
      </b-popover>
    </div>
   
  </span>
</template>
